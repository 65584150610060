import React from "react"
import Container from "../components/container"
import FinalCTA from "../components/final-cta"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = () => {
  return (
    <Layout>
      <Container>
        <div className="text-center pt-24 pb-24" style={{ zIndex: 30 }}>
          <h1 className=" mb-4 text-6xl mt-0 tracking-normal">
            Thanks for Subscribing!
          </h1>
        </div>
      </Container>
      <FinalCTA />
    </Layout>
  )
}

export const Head = () => <Seo title={"Success"} />

export default Contact
